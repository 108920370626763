import React from "react";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./About_Us/AboutUs";
import GivingBack from "./About_Us/Giving_Back/GivingBack";
import HappyCustomers from "./About_Us/Happy_Customers/HappyCustomers";
import CareMaintenance from "./Care_Maintenance/CareMaintenance";
import Contact from "./Contact/Contact";
import CookieConsentBanner from "./CookieConsentBanner";
import FAQs from "./FAQs/FAQs";
import Financing from "./Financing/Financing";
import Synchrony from "./Financing/Synchrony/Synchrony";
import Home from "./Home/Home";
import NotFound from "./NotFound/NotFound";
import Product from "./Products_Services/ProductPage/Product";
import ProductsServices from "./Products_Services/ProductsServices";

const Body = ({
  dbSettings,
  products,
  colors,
  productsServicesDropdownIcon,
  productsServicesDropdownAction,
  aboutUsDropdownIcon,
  setAboutUsDropdownIcon,
}) => {
  const homeSettings = dbSettings[0];
  const careMaintenanceSettings = dbSettings[1];
  const faqsSettings = dbSettings[2];
  const aboutUsSettings = dbSettings[3];
  const givingBackSettings = dbSettings[4];
  const happyCustomersSettings = dbSettings[5];
  const contactSettings = dbSettings[6];
  const financingSettings = dbSettings[7];

  // const homeSettings = dbSettings.filter((x) => x.Name == "Home")[0];
  // const careMaintenanceSettings = dbSettings.filter(
  //   (x) => (x.Name = "CareMaintenance")
  // )[0];

  const productsServicesNestedDropdownId = "productsServicesDropdownMenu";
  const aboutUsNestedDropdownId = "aboutUsDropdownMenu";

  const nestedDropdownIds = [
    productsServicesNestedDropdownId,
    aboutUsNestedDropdownId,
  ];

  const productsServicesNestedDropdownIdMobile =
    "productsServicesDropdownMenuMobile";
  const aboutUsNestedDropdownIdMobile = "aboutUsDropdownMenuMobile";

  const nestedDropdownIdsMobile = [
    productsServicesNestedDropdownIdMobile,
    aboutUsNestedDropdownIdMobile,
  ];

  return (
    <div
      // data-bs-toggle="offcanvas"
      // data-bs-target="#navbarNavDropdown"
      // aria-controls="navbarNavDropdown"
      onClick={() => {
        nestedDropdownIds.map((id) => {
          if (document.getElementById(id).classList.contains("d-block")) {
            document.getElementById(id).classList.remove("d-block");
          }
        });

        nestedDropdownIdsMobile.map((id) => {
          if (document.getElementById(id).classList.contains("d-block")) {
            document.getElementById(id).classList.remove("d-block");
          }
        });

        if (productsServicesDropdownIcon == "caret-up-fill") {
          productsServicesDropdownAction("caret-down-fill");
        }

        if (aboutUsDropdownIcon == "caret-up-fill") {
          setAboutUsDropdownIcon("caret-down-fill");
        }
      }}
    >
      <Routes>
        <Route
          exact
          path="/"
          element={
            <Home
              dbSettings={homeSettings}
              products={products}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/products-services"
          element={<ProductsServices products={products} colors={colors} />}
        />
        {products.map((product) => (
          <Route
            key={`product${products.indexOf(product)}`}
            exact
            path={product.LinkTo}
            element={<Product product={product} colors={colors} />}
          />
        ))}
        <Route
          exact
          path="/care-maintenance"
          element={
            <CareMaintenance
              dbSettings={careMaintenanceSettings}
              products={products.filter(
                (x) => x.CareMaintenanceSections != null
              )}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/faqs"
          element={<FAQs dbSettings={faqsSettings} colors={colors} />}
        />
        <Route
          exact
          path="/about-us"
          element={<AboutUs dbSettings={aboutUsSettings} colors={colors} />}
        />
        <Route
          exact
          path="/about-us/giving-back"
          element={<GivingBack dbSettings={givingBackSettings} />}
        />
        <Route
          exact
          path="/about-us/happy-customers"
          element={
            <HappyCustomers
              dbSettings={happyCustomersSettings}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/contact"
          element={
            <Contact
              dbSettings={contactSettings}
              products={products}
              colors={colors}
            />
          }
        />
        <Route
          exact
          path="/financing"
          element={<Financing dbSettings={financingSettings} />}
        />
        <Route path="/financing/synchrony" element={<Synchrony />} />
        <Route exact path="*" element={<NotFound colors={colors} />} />
      </Routes>
    </div>
  );
};

export default Body;
