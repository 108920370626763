import React, { useEffect, useState } from "react";
import Body from "./components/Body/Body";
import Footer from "./components/Footer/Footer";
import { fetchData } from "./AwsFunctions.js";
import "./App.css";
import * as AWS from "aws-sdk";
import { GetDefaultData } from "./DefaultData.js";
import NavBar from "./components/NavBar/NavBar.jsx";

const App = () => {
  const [DbSettings, setDbSettings] = useState([]);

  const [productsServicesDropdownIcon, setProductsServicesDropdownIcon] =
    useState("caret-down-fill");

  const [aboutUsDropdownIcon, setAboutUsDropdownIcon] =
    useState("caret-down-fill");

  useEffect(() => {
    // const environment = "test";
    const environment = "prod";

    const apiUrl =
      environment == "prod"
        ? "https://8avsuri1h9.execute-api.us-west-1.amazonaws.com/Production/id/0"
        : "https://2bjti24mme.execute-api.us-east-1.amazonaws.com/Test/id/0";

    try {
      fetchData(apiUrl)
        .then((x) => x.json())
        .then((x) => {
          const unmarshalledData = AWS.DynamoDB.Converter.unmarshall(
            x.Items[0]
          );
          setDbSettings(unmarshalledData);
        });
    } catch (error) {
      setDbSettings(GetDefaultData());
    }
  }, []);

  const promotionElement = document.getElementById("archiveList");

  if (promotionElement && promotionElement.childElementCount > 0) {
    return <div className="mx-auto" />;
  }

  promotionElement.style.marginTop = "1rem";
  promotionElement.style.marginLeft = "1rem";
  promotionElement.style.marginRight = "1rem";
  promotionElement.style.marginBottom = "1.5rem";
  promotionElement.classList.add("shadow");
  promotionElement.classList.add("rounded-3");
  promotionElement.classList.add("p-3");

  if (DbSettings.length == 0) {
    return <div className="mx-auto" />;
  }

  return (
    <div id="appDiv" className="mx-auto">
      <div id="navAndBody">
        <NavBar
          dbSettings={DbSettings.NavBar}
          colors={DbSettings.WebsiteColors}
          productsServicesDropdownIcon={productsServicesDropdownIcon}
          productsServicesDropdownAction={setProductsServicesDropdownIcon}
          aboutUsDropdownIcon={aboutUsDropdownIcon}
          setAboutUsDropdownIcon={setAboutUsDropdownIcon}
        />
        <Body
          dbSettings={DbSettings.Pages}
          products={DbSettings.Products}
          colors={DbSettings.WebsiteColors}
          productsServicesDropdownIcon={productsServicesDropdownIcon}
          productsServicesDropdownAction={setProductsServicesDropdownIcon}
          aboutUsDropdownIcon={aboutUsDropdownIcon}
          setAboutUsDropdownIcon={setAboutUsDropdownIcon}
        />
      </div>
      <Footer
        id="footer"
        dbSettings={DbSettings.Footer}
        colors={DbSettings.WebsiteColors}
      />
    </div>
  );
};

export default App;
